import React from "react";
import Message from "./Message";
import SendMessage from "./SendMessage";
import { useEffect, useRef, useState } from "react";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { db } from "../firebase";
import styled from "styled-components";
import backgroundImage from "../img/chat_background.png"

const ChatBoxContainer = styled.main`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  position: relative;
  color: white;
  height: 90vh;
  overflow-y: scroll;
  padding: 10px 5px 0px 5px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  &:before {
    content: "";
    position: fixed; // This makes sure the overlay is fixed along with the background
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5); // Adjust the opacity as needed
    border-radius: 8px; // To match the container's border radius
    pointer-events: none; // This ensures clicks pass through to elements underneath
    z-index: 0; // Places the overlay behind the content
  }

  // Ensure the content sits on top of the pseudo-element
  & > * {
    position: relative;
    z-index: 1;
  }
`;


const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChatBox = () => {
  const [messages, setMessages] = useState([]);
  const scroll = useRef();

  useEffect(() => {
    const q = query(
      collection(db, "messages"),
      orderBy("createdAt", "desc"),
      limit(50)
    );
    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      const fetchedMessages = [];
      QuerySnapshot.forEach((doc) => {
        fetchedMessages.push({ ...doc.data(), id: doc.id });
      });
      const sortedMessages = fetchedMessages.sort(
        (a, b) => a.createdAt - b.createdAt
      );
      setMessages(sortedMessages);
    });
    return () => unsubscribe;
  }, []);
  return (
    <ChatBoxContainer>
      <MessagesWrapper>
        {messages?.map((message) => (
          <Message key={message.id} message={message} />
        ))}
      </MessagesWrapper>
      <span ref={scroll}></span>
      <SendMessage scroll={scroll} />
    </ChatBoxContainer>
  );
};

export default ChatBox;
