import React, { useState } from "react";
import { auth, db } from "../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import styled from "styled-components";

const SendMessageForm = styled.form`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px; // Increased padding for larger input
  position: fixed;
  border-radius: 25px 25px 0px 0px; /*TL TR BR BL*/
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const MessageInput = styled.input`
  flex-grow: 1;
  border: none;
  border-radius: 25px;
  padding: 15px 20px; // Larger padding for a bigger input
  font-size: 1.1rem; // Larger font size for better readability
  background: rgba(255, 255, 255, 0.5);
  &:focus {
    outline: none;
    background: rgba(255, 255, 255, 0.7);
  }
`;

const SendButton = styled.button`
  background-color: #2f4e3c;
  color: white;
  border: none;
  padding: 15px 20px; // Increased padding to match the larger input
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: orange;
  }
`;

const MessagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px; // Padding bottom equals the height of the SendMessageForm plus its top and bottom padding
`;

const SendMessage = ({ scroll }) => {
  const [message, setMessage] = useState("");

  const sendMessage = async (event) => {
    event.preventDefault();
    if (message.trim() === "") {
      alert("Enter valid message");
      return;
    }
    const { uid, displayName, photoURL } = auth.currentUser;
    await addDoc(collection(db, "messages"), {
      text: message,
      name: displayName,
      avatar: photoURL,
      createdAt: serverTimestamp(),
      uid,
    });
    setMessage("");
    scroll.current.scrollIntoView({ behavior: "smooth" });

  };

  return (
    <SendMessageForm onSubmit={(event) => sendMessage(event)}>
      <MessageInput
        id="messageInput"
        name="messageInput"
        type="text"
        placeholder="type message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <SendButton type="submit">Send</SendButton>
    </SendMessageForm>
  );
};

export default SendMessage;
