import React from "react";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import styled from "styled-components";

// Function to convert a UID to a color
const getHashColor = (uid) => {
  let hash = 0;
  for (let i = 0; i < uid.length; i++) {
    hash = uid.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 50%, 50%)`;
  return color;
};

const UserName = styled.p`
  color: ${(props) => getHashColor(props.uid)};
  margin: 0;
  font-weight: bold;
`;

const ChatBubble = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: ${(props) =>
    props.isCurrentUser ? "flex-end" : "flex-start"};
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 3px;
  margin-left: 3px;
  border-radius: 20px;
`;

const BubbleContent = styled.div`
  max-width: 75%;
  padding: 10px;
  border-radius: 15px;
  background: ${(props) =>
    props.isCurrentUser ? "rgba(47, 78, 60, 0.8)" : "rgba(255, 255, 255, 0.8)"};
  backdrop-filter: blur(4px);
  color: ${(props) => (props.isCurrentUser ? "white" : "black")};
  border: 1px solid
    ${(props) =>
      props.isCurrentUser
        ? "rgba(47, 78, 60, 0.5)"
        : "rgba(255, 255, 255, 0.5)"};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;


const Message = ({ message }) => {
  const [user] = useAuthState(auth);
  const isCurrentUser = message.uid === user.uid;

  return (
    <ChatBubble isCurrentUser={isCurrentUser}>
      {!isCurrentUser && <Avatar src={message.avatar} alt="user avatar" />}
      <BubbleContent isCurrentUser={isCurrentUser}>
        <UserName uid={message.uid}>{message.name}</UserName>
        <p>{message.text}</p>
      </BubbleContent>
      {isCurrentUser && <Avatar src={message.avatar} alt="user avatar" />}
    </ChatBubble>
  );
};
export default Message;
